@import "../bootstrap_vars";
@import "../../../node_modules/bootstrap/scss/mixins";

.g-searchbar {
    text-align: left !important;
    color: #555;

    &.--offset-top-xs {
        position: relative;
        top: -40px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 20px;
            position: unset;
            top: 0;
        }
    }

    input[type="text"], select {
        padding-left: 21px;
        padding-top: 0.475rem;
        height: calc(1.5em + 0.75rem + 12px);
    }

    .form-control {
        border: none;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 100px !important;
        border-bottom-left-radius: 100px !important;
    }

    .input-group {
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
        border-top-right-radius: 100px !important;
        border-bottom-right-radius: 100px !important;
        border-top-left-radius: 100px !important;
        border-bottom-left-radius: 100px !important;

        // easy auto complete
        .easy-autocomplete {
            position: relative;
            z-index: 1000;
            -webkit-box-flex: 1;
            flex: 1 1 0%;
            min-width: 0;
            margin-bottom: 0;
        }
    }

    .easy-autocomplete-container {
        z-index: 1000;

        ul li {
            padding: 8px 16px !important;
        }

        .eac-item {
            color: #555 !important;
            padding: 8px 6px;
            font-size: 1rem;

            i, svg {
                display: inline-block;
                margin-right: 12px;
            }

            small {
                float: right;
                text-transform: capitalize;
                margin-left: 10px;
            }
        }
    }

    button.btn {
        padding: 0.5rem 2.4rem !important;
        box-shadow: none;
        border-top-right-radius: 100px !important;
        border-bottom-right-radius: 100px !important;

        i {
            left: -12px;
            position: relative;
        }
    }
}

